<template>
  <YmiNotifications
    :mapped-store="notificationStore"
    default-position="top-right"
  />

  <!-- <div class="fixed bottom-6 right-6">
    <div
      v-for="item in notificationStore.noties"
      :key="item.id"
      :class="item.class"
    >
      <p>{{ item.id }}</p>
      <p>
        {{ item.title }}
      </p>

      <p>{{ item.message }}</p>
    </div>
  </div> -->
</template>

<script setup>
import { useNotieStore } from '~/store/notification';

const notificationStore = useNotieStore();
</script>

<style lang="scss" scoped></style>
